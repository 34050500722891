import { Component, OnInit,Input } from '@angular/core';
import { ModalController,ToastController } from '@ionic/angular';
import { AlmacenamientoLocalService } from '../services/almacenamiento-local.service';
import { Router } from '@angular/router';

interface Carrito {
  Productos: Producto[],
  Total: number,
  NumProductos: number
}

interface Producto {
  Cve_Art: string,
  Cantidad: number,
  Cve_Alm: number,
  Costo: number,
  Precio: number,
  Descr: string,
  Imagen: string,
  Costo_Prom: number,
  Cliente: string
}



@Component({
  selector: 'app-det-producto',
  templateUrl: './det-producto.page.html',
  styleUrls: ['./det-producto.page.scss'],
})
export class DetProductoPage implements OnInit {

 
  Prod: Producto;  
  carrito: Producto[]=[];
  NoSeEncontro=true;
  SeVaAlCarro=false;
  ItemsGuardados: Producto[]=[];  
  //Returno: any;
  
  @Input() IdEmpresa;
  @Input() ProductoActual;
  @Input() MuestraExistencia;
  @Input() PreciosConImpuestos;
  @Input() TotalItems;
  @Input() Almacen;
  @Input() Cliente;
  

  constructor(private ModalCtrl: ModalController,
              private LocalConfig: AlmacenamientoLocalService,
              private Toast: ToastController,
              private Ruteo: Router,) { }

  
  async CierraVentana() {
    const onClosedData: string = "Wrapped Up!";
    
    await this.ModalCtrl.dismiss(this.SeVaAlCarro);
  }

  IrACarro(){   
    this.SeVaAlCarro=true;    
    this.CierraVentana();
  }

  AddCart(){

    this.NoSeEncontro=true;
    this.carrito = [];

    this.ItemsGuardados=this.LocalConfig.get('carrito');

    if(this.ItemsGuardados !== null)
		{	
	
      for (var i = 0; i < this.ItemsGuardados.length; i++) {
         if(this.ProductoActual.CVE_ART === this.ItemsGuardados[i].Cve_Art)	
			   {
            this.Prod={
              Cve_Art: this.ItemsGuardados[i].Cve_Art,
              Cantidad: this.ItemsGuardados[i].Cantidad + 1 ,
              Cve_Alm: this.ItemsGuardados[i].Cve_Alm,
              Costo: this.ItemsGuardados[i].Costo,
              Precio: this.ItemsGuardados[i].Precio,
              Descr: this.ItemsGuardados[i].Descr,
              Imagen: this.ItemsGuardados[i].Imagen,
              Costo_Prom:  this.ItemsGuardados[i].Costo_Prom,
              Cliente: this.ItemsGuardados[i].Cliente             
            };   
            this.ItemsGuardados[i].Cantidad++;
            this.NoSeEncontro=false;          
			   }	  
         this.carrito.push(this.ItemsGuardados[i]);
		  } 
    } 
     
  
       if(this.NoSeEncontro)
       {
          this.Prod={
          Cve_Art: this.ProductoActual.CVE_ART,
          Cantidad:1,
          Cve_Alm: this.Almacen,
          Costo: this.ProductoActual.COSTO,
          Precio: this.ProductoActual.PRECIO,
          Descr: this.ProductoActual.DESCR,
          Imagen: this.ProductoActual.IMAGEN,
          Costo_Prom: this.ProductoActual.COSTO_PROM, 
          Cliente: this.Cliente
          };
          this.carrito.push(this.Prod);		
          
       } 

       console.log("antes de carrito nuevo: " +   JSON.stringify(this.carrito));
       this.presentToastWithOptions(); 
       this.LocalConfig.set('carrito',this.carrito);
       this.totalIems();
       //console.log(" carrito nuevo: " +   JSON.stringify(this.carrito));
  } 

  async presentToastWithOptions() {
    const toast = await this.Toast.create({
      duration: 2000,
      message: 'Producto agregado al carrito',
      position: 'bottom',      
      cssClass: 'Estilo-toast',
      buttons: [
        {
          side: 'end',
          icon: 'cart'         
        }        
      ]
    });
    toast.present();
  }

  totalIems() {
    this.TotalItems=0.0;
    this.ItemsGuardados=this.LocalConfig.get('carrito'); 
    if(this.ItemsGuardados !== null) 
    {
      for (var i = 0; i < this.ItemsGuardados.length; i++)  {       
        this.TotalItems += this.ItemsGuardados[i].Cantidad*1;
      }
    }  	  
    console.log('Entro a contar items:' + this.TotalItems);
  }  

  ngOnInit() {
    console.log('Precios con Impuestos:' + this.PreciosConImpuestos);
    console.log('Producto:' + this.ProductoActual);
    this.ItemsGuardados=this.LocalConfig.get('carrito');
  }

}
